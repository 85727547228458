// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import * as bootstrap from "bootstrap";
import { L } from "leaflet";
import "./show_map";
import LocalTime from "local-time";
LocalTime.start();
import './browser_timezone_cookie';
window.bootstrap = bootstrap;
