import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const alertNode = document.querySelector(".alert");
    const alert = bootstrap.Alert.getOrCreateInstance(alertNode);
    setTimeout(() => {
      alert.close();
    }, 3000);
  }
}
